<script>
// controls expanded vs condensed
import { hasVModel } from "@/mixins/vmodel";
import { hasRouteDay } from "@/mixins/routerParams";

import DayKpiText from "../DayKpiText";
import KpisDesktopExpanded from "./KpisDesktopExpanded";
import RaiExpansionFooter from "@/components/RaiExpansionFooter.vue";

export default {
  name: "KpisDesktop",
  components: {
    DayKpiText,
    RaiExpansionFooter,
    KpisDesktopExpanded,
  },
  mixins: [hasVModel, hasRouteDay],
  props: {
    kpi: {
      type: Object,
      required: true,
    },
    comps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      display: true,
      expanded: false,
      hover: false,
      bins: {},
    };
  },
  computed: {
    showHover() {
      return this.hover ? this.hover : this.expanded;
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    formatData(data) {
      if (!data) return "0";
      data = (Math.round(data * 100) / 100).toFixed(2);
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    computedColor(actual, goal, type = null) {
      if (!goal) return null;
      if (
        !this.kpi.closed &&
        (type === "sales" ||
          (type === "trade" && this.kpi.buysSorted < 1) ||
          (type === "trans" && this.kpi.transactions < 1))
      ) {
        return actual > goal ? "success--text" : "info--text";
      } else {
        return actual > goal ? "success--text" : "error--text";
      }
    },
    computedColorBar(actual, goal, type = null) {
      if (!goal) return null;
      if (
        !this.kpi.closed &&
        (type === "sales" ||
          (type === "trade" && this.kpi.buysSorted < 1) ||
          (type === "trans" && this.kpi.transactions < 1))
      ) {
        return actual > goal ? "success" : "info";
      } else {
        return actual > goal ? "success" : "error";
      }
    },
    goalDifference(actual, goal) {
      var diff = actual - goal;
      return `${diff > 0 ? "+" : "-"} $${this.formatData(Math.abs(diff))}`;
    },
  },
};
</script>

<template>
  <RaiExpansionFooter app inset padless>
    <template #activator="{ on, attrs }">
      <v-row data-testid="kpisRow" class="py-2 px-4" v-bind="attrs" v-on="on">
        <v-col>
          <span class="text-subtitle-1 text--secondary">Sales</span>
          <DayKpiText
            prefix="$"
            :data="kpi.sales"
            :color="computedColor(kpi.sales, kpi.salesGoal, 'sales')"
          />
          <v-progress-linear
            :value="(kpi.sales / kpi.salesGoal) * 100"
            :color="computedColorBar(kpi.sales, kpi.salesGoal, 'sales')"
          />
        </v-col>
        <v-col>
          <span class="text-subtitle-1 text--secondary">Avg Trans</span>
          <DayKpiText
            prefix="$"
            :data="kpi.avgTrans"
            :color="computedColor(kpi.avgTrans, kpi.avgTransGoal, 'trans')"
          />
        </v-col>
        <v-col>
          <span class="text-subtitle-1 text--secondary">Trade %</span>
          <DayKpiText
            suffix="%"
            :data="kpi.tradePct"
            :color="computedColor(kpi.tradePct, kpi.tradePctGoal, 'trade')"
          />
        </v-col>
      </v-row>
    </template>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <div>
            <span class="text-overline">Goal:</span>
            <span class="rai-data ml-6 text--primary font-weight-medium"
              >${{ formatData(kpi.salesGoal) }}</span
            >
            <span
              v-if="kpi.closed"
              :class="`rai-data ${computedColor(
                kpi.sales,
                kpi.salesGoal
              )} ml-6`"
              >{{ goalDifference(kpi.sales, kpi.salesGoal) }}</span
            >
          </div>
          <div>
            <span class="text-overline">Comps:</span>
            <span class="rai-data ml-3 text--primary font-weight-medium"
              >${{ formatData(comps.sales) }}</span
            >
          </div>
        </v-col>
        <v-col cols="4">
          <div>
            <span class="text-overline">Goal:</span>
            <span class="rai-data ml-6 text--primary font-weight-medium"
              >${{ formatData(kpi.avgTransGoal) }}</span
            >
          </div>
          <div>
            <span class="text-overline">Comps:</span>
            <span class="rai-data ml-3 text--primary font-weight-medium"
              >${{ formatData(comps.avgTrans) }}</span
            >
          </div>
        </v-col>
        <v-col cols="4">
          <div>
            <span class="text-overline">Goal:</span>
            <span class="rai-data ml-6 text--primary font-weight-medium"
              >{{ formatData(kpi.tradePctGoal) }}%</span
            >
          </div>
          <div>
            <span class="text-overline">Comps:</span>
            <span class="rai-data ml-3 text--primary font-weight-medium"
              >{{ formatData(comps.tradePct) }}%</span
            >
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <KpisDesktopExpanded :kpi="kpi" :comps="comps" />
    </v-card-text>
  </RaiExpansionFooter>
</template>
