<script>
import TaskList from "@/components/daybook/tasks/TaskList.vue";
import TodoListCard from "@/components/daybook/tasks/TodoListCard.vue";

import VxCol from "@/components/vx/VxCol";

export default {
  name: "TasksDesktop",
  components: {
    TaskList,
    TodoListCard,
    VxCol,
  },
};
</script>

<template>
  <VxCol>
    <TodoListCard />
    <TaskList />
    <v-spacer vertical size="64" />
  </VxCol>
</template>
