<script>
import Notes from "@/components/daybook/Notes";

import VxCol from "@/components/vx/VxCol";

export default {
  name: "NotesDesktop",
  components: {
    VxCol,
    Notes,
  },
};
</script>

<template>
  <VxCol scroll>
    <Notes />
  </VxCol>
</template>
