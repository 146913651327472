<script>
import { hasRouteTab, hasRouteDay } from "@/mixins/routerParams";

import TasksDesktop from "./tasks/TasksDesktop";
import NotesDesktop from "./notes/NotesDesktop";
import KpisDesktop from "./kpis/KpisDesktop";
import ShiftsDesktop from "./shifts/ShiftsDesktop";

import RaiSpeedDial from "@/components/daybook/SpeedDial";

import { KPI_UPDATED } from "./kpis/subscriptions.gql";
import { Kpi, Comps } from "./kpis/queries.gql";
import { handleKpiUpdated } from "./kpis/subscription_events";

import { parseISO, format } from "date-fns";
import { DRAWER_MINI_VARIANT_WIDTH, DRAWER_WIDTH } from "./shifts/constants";

export default {
  name: "DayViewDesktop",
  components: {
    TasksDesktop,
    NotesDesktop,
    KpisDesktop,
    ShiftsDesktop,
    RaiSpeedDial,
  },
  mixins: [hasRouteTab, hasRouteDay],
  data() {
    return {
      kpi: {},
      comps: {},
    };
  },
  computed: {
    storeId() {
      return this.$route.params.storeId;
    },
    variables() {
      return {
        storeId: this.storeId,
        businessDate: this.day,
      };
    },
    compVariables() {
      const today = parseISO(this.day);
      const oneYearAgoToday = today.setDate(today.getDate() - 52 * 7);

      return {
        storeId: this.storeId,
        businessDate: format(oneYearAgoToday, "yyyy-MM-dd"),
      };
    },
    containerStyle() {
      return {
        position: "relative",
        paddingRight: this.$vuetify.breakpoint.lgAndDown
          ? `${DRAWER_MINI_VARIANT_WIDTH + 12}px` // -> magic number is offset to accomodate for scrollbars and padding
          : `${DRAWER_WIDTH + 12}px`,
      };
    },
    shouldSkipQueries() {
      return !this.storeId;
    },
    // redundant. See routerParams.js for dayIsPast()
    notPast() {
      return !this.dayIsPast;
      //return this.dayDate >= startOfDay(new Date());
    },
  },
  mounted() {
    this.defaultToToday();
  },
  apollo: {
    kpi: {
      query: Kpi,
      subscribeToMore: [
        {
          document: KPI_UPDATED,
          update_query(
            previousResult = { kpis: [] },
            {
              subscriptionData: {
                data: { kpiUpdated },
              },
            }
          ) {
            return handleKpiUpdated(previousResult, kpiUpdated);
          },
        },
      ],
      variables() {
        return this.variables;
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.kpi;
      },
      skip() {
        return this.shouldSkipQueries;
      },
    },
    comps: {
      query: Comps,
      variables() {
        return this.compVariables;
      },
      fetchPolicy: "cache-first",
      update(data) {
        return data.kpi;
      },
    },
  },
};
</script>

<template>
  <v-container fluid class="py-0 fill-height" :style="containerStyle">
    <v-row class="py-0">
      <!-- scrollable col -->
      <TasksDesktop />
      <!-- scrollable col -->
      <NotesDesktop />
    </v-row>

    <RaiSpeedDial v-if="notPast" />

    <!-- nav drawer right -->
    <ShiftsDesktop />

    <!-- bottom footer -->
    <KpisDesktop :kpi="kpi" :comps="comps" />
  </v-container>
</template>
