<script>
export default {
  name: "VxCol",
  props: {
    scroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      offset: 64,
    };
  },
  computed: {
    shouldTouchScroll() {
      return this.$raiPos && this.$vuetify.breakpoint.mdAndUp;
    },
    styles() {
      return {
        height: `calc(100vh - ${
          this.$vuetify.application.top +
          this.$vuetify.application.bottom +
          this.$vuetify.application.insetFooter
        }px)`,
        overflowY: "scroll",
        overflowX: "hidden",
        paddingBottom: `${this.offset}px`,
      };
    },
  },
};
</script>

<template>
  <v-col class="vx-panel vx-panel--scroll rai-scrollbar pa-0">
    <div :style="styles">
      <slot />
    </div>
  </v-col>
</template>

<style lang="scss">
.grab-bing {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: grab;
}

.grab-bing:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: grabbing;
}
.vx-panel {
  grid-template-columns: auto;
  height: inherit;
  overflow: hidden;

  .vx-panel__header {
    grid-area: header;
  }

  .vx-panel__content {
    height: inherit;
    grid-area: content;
  }

  &.vx-panel--has-header {
    display: grid;
    grid-template-rows: 32px 1fr;
    grid-template-areas:
      "header"
      "content";
  }

  &.vx-panel--scroll {
    .vx-panel__content {
      overflow-y: scroll;
    }
  }
}
</style>
