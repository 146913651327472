<template>
  <div class="daybook-speed-dial">
    <v-overlay :value="fab" />
    <v-speed-dial v-model="fab" style="z-index: 5">
      <template #activator>
        <v-btn
          v-model="fab"
          dark
          large
          fab
          :color="fab ? 'grey darken-4' : 'primary'"
          data-testid="daybookSpeedDialBtn"
        >
          <v-icon v-text="fab ? '$close' : '$add'"></v-icon>
        </v-btn>
      </template>

      <div class="daybook-speed-dial__item">
        <v-chip>Task</v-chip>
        <v-btn
          color="primary"
          fab
          data-testid="daybookNewTaskBtn"
          @click="handleClickTask"
        >
          <v-icon>$taskFab</v-icon>
        </v-btn>
      </div>
      <div class="daybook-speed-dial__item">
        <v-chip>Note</v-chip>
        <v-btn
          color="primary"
          fab
          data-testid="daybookNewNoteBtn"
          @click="handleClickNote"
        >
          <v-icon>$noteFab</v-icon>
        </v-btn>
      </div>
      <div class="daybook-speed-dial__item">
        <v-chip>Shift</v-chip>
        <EmployeeAction v-slot="{ handleAction }" check-manager>
          <v-btn
            color="primary"
            fab
            data-testid="daybookNewShiftBtn"
            @click="handleAction(handleClickShift)"
          >
            <v-icon>$shiftFab</v-icon>
          </v-btn>
        </EmployeeAction>
      </div>
    </v-speed-dial>
  </div>
</template>

<script>
import EmployeeAction from "@/components/employees/EmployeeAction.js";
import { injectActiveEmployee } from "@/mixins/employee";

export default {
  name: "DaybookSpeedDial",
  components: { EmployeeAction },
  mixins: [injectActiveEmployee],
  data: () => ({
    fab: false,
  }),
  methods: {
    handleClickTask() {
      this.$router.push({
        name: "newTaskDialog",
      });
    },
    handleClickNote() {
      this.$router.push({
        name: "newNoteDialog",
      });
    },
    handleClickShift() {
      this.$router.push({
        name: "newShiftDialog",
      });
    },
  },
};
</script>

<style lang="scss">
.daybook-speed-dial {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.daybook-speed-dial__item {
  position: relative;

  & > .v-chip {
    position: absolute;
    border-radius: 5px !important;
    min-width: max-content !important;
    top: 18px;
    left: -65px;
  }
}
</style>
