<script>
import { hasRouteDay } from "@/mixins/routerParams";

import { Bins } from "../queries.gql";

import { format, parseISO } from "date-fns";

export default {
  name: "KpisDesktopExpanded",
  mixins: [hasRouteDay],
  props: {
    kpi: {
      type: Object,
      required: true,
    },
    comps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bins: {
        binsAdded: [],
        binsPulled: [],
      },
    };
  },
  computed: {
    formattedCompDate() {
      return format(parseISO(this.comps.businessDate), "iiii MMMM do, yyyy");
    },
  },
  methods: {
    formattedDate(date) {
      return format(parseISO(date), "H:mm");
    },
  },
  apollo: {
    bins: {
      query: Bins,
      variables: function () {
        return {
          storeId: this.$route.params.storeId,
          businessDate: this.day,
        };
      },
      update(data) {
        return data.kpi;
      },
    },
  },
};
</script>

<template>
  <v-row data-testid="kpisExpanded">
    <v-col cols="4">
      <p class="text--primary text-subtitle-1 font-weight-medium mb-4">
        Sales & Labor
      </p>
      <v-row class="mx-0">
        <v-col class="py-2 px-0">
          <span class="text--secondary">Transactions</span>
        </v-col>
        <v-col class="py-2 px-0 text-right">
          <span
            v-if="kpi.transactions !== null"
            class="text--primary font-weight-medium rai-data"
            >{{ kpi.transactions }}</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
      </v-row>
      <!-- TODO: handle manager content -->
      <template v-if="$store.getters['auth/isManager']">
        <v-row class="mx-0">
          <v-col class="py-2 px-0">
            <span class="text--secondary">Total labor hours</span>
          </v-col>
          <v-col class="py-2 px-0 text-right">
            <span
              v-if="kpi.laborHours !== null"
              class="text--primary font-weight-medium rai-data"
              >{{ kpi.laborHours }}</span
            >
            <span v-else class="text--primary font-weight-medium rai-data"
              >--</span
            >
          </v-col>
        </v-row>
        <v-row class="mx-0">
          <v-col class="py-2 px-0">
            <span class="text--secondary">Sales / labor hour</span>
          </v-col>
          <v-col class="py-2 px-0 text-right">
            <span
              v-if="kpi.salesLaborHr !== null"
              class="text--primary font-weight-medium rai-data"
              >${{ kpi.salesLaborHr }}</span
            >
            <span v-else class="text--primary font-weight-medium rai-data"
              >--</span
            >
          </v-col>
        </v-row>
        <v-row class="mx-0">
          <v-col class="py-2 px-0">
            <span class="text--secondary">Total wages</span>
          </v-col>
          <v-col class="py-2 px-0 text-right">
            <span
              v-if="kpi.payroll !== null"
              class="text--primary font-weight-medium rai-data"
              >${{ kpi.payroll }}</span
            >
            <span v-else class="text--primary font-weight-medium rai-data"
              >--</span
            >
          </v-col>
        </v-row>
      </template>
      <!-- <v-row class="mx-0" v-if="kpi.closed">
        <v-col class="py-2 px-0">
          <span class="text--secondary">Cash variance</span>
        </v-col>
        <v-col class="py-2 px-0">
          <span class="text--primary font-weight-medium rai-data"
            >$0.{{ kpi.transactions }}</span
          >
        </v-col>
      </v-row>-->
      <v-row class="mx-0">
        <v-col class="py-2 px-0">
          <span v-if="comps.businessDate" class="text--primary"
            >*Comps: comparison to {{ formattedCompDate }}</span
          >
          <span v-else class="text--primary"
            >*Comps: no data for comparison</span
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="4">
      <p class="text--primary text-subtitle-1 font-weight-medium">Backstock</p>
      <v-row class="mx-0">
        <v-col class="py-2 px-0">
          <span class="text--primary"
            >{{ bins.binsPulled.length }} bins pulled today</span
          >
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-simple-table
          dense
          style="width: 100%"
          :height="
            bins.binsPulled.length < 6 ? 33 * bins.binsPulled.length : 200
          "
          class="rai-scrollbar"
        >
          <tbody>
            <tr v-for="item in bins.binsPulled" :key="item.id">
              <td class="text-caption">{{ formattedDate(item.archivedAt) }}</td>
              <td class="text-caption">{{ item.bin.name }}</td>
              <td class="text-caption">{{ item.category.name }}</td>
              <td class="text-caption">{{ item.notes }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-row>
      <v-row class="mx-0 mt-4">
        <v-col class="py-2 px-0">
          <span class="text--primary"
            >{{ bins.binsAdded.length }} bins added today</span
          >
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-simple-table
          dense
          style="width: 100%"
          :height="bins.binsAdded.length < 6 ? 33 * bins.binsAdded.length : 200"
          class="rai-scrollbar"
        >
          <tbody>
            <tr v-for="item in bins.binsAdded" :key="item.id">
              <td class="text-caption">{{ formattedDate(item.createdAt) }}</td>
              <td class="text-caption">{{ item.bin.name }}</td>
              <td class="text-caption">{{ item.category.name }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-row>
    </v-col>
    <v-col cols="4">
      <p class="text--primary text-subtitle-1 font-weight-medium">Buys</p>
      <v-row class="mx-0">
        <v-col class="py-2 px-0">
          <span class="text--secondary">Buys</span>
        </v-col>
        <v-col class="py-2 px-0 text-right">
          <span
            v-if="kpi.buys !== null"
            class="text--primary font-weight-medium rai-data"
            >${{ kpi.buys }}</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
        <v-col class="py-2 px-0 text-right">
          <span
            v-if="kpi.numBuys !== null"
            class="text--primary font-weight-medium rai-data"
            >{{ kpi.numBuys }} buys</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="py-2 px-0">
          <span class="text--secondary">Buys comp</span>
        </v-col>
        <v-col class="py-2 px-0 text-right">
          <span
            v-if="comps.buys !== null"
            class="text--primary font-weight-medium rai-data"
            >${{ comps.buys }}</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
        <v-col class="py-2 px-0 text-right">
          <span
            v-if="comps.numBuys !== null"
            class="text--primary font-weight-medium rai-data"
            >{{ comps.numBuys }} buys</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="4" class="py-2 px-0">
          <span class="text--secondary">Trade</span>
        </v-col>
        <v-col cols="4" class="py-2 px-0 text-right">
          <span
            v-if="kpi.trades !== null"
            class="text--primary font-weight-medium rai-data"
            >${{ kpi.trades }}</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
        <v-col cols="4" class="py-2 px-0 text-right">
          <span
            v-if="kpi.numTrades !== null"
            class="text--primary font-weight-medium rai-data"
            >{{ kpi.numTrades }} trades</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
        <v-col cols="4" class="py-2 px-0">
          <span class="text--secondary">% traded</span>
        </v-col>
        <v-col cols="4" class="py-2 px-0 text-right">
          <span
            v-if="!!kpi.buys"
            class="text--primary font-weight-medium rai-data"
            >{{ Math.round((100 * kpi.trades) / kpi.buys) }}%</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
        <v-col cols="4" class="py-2 px-0 text-right">
          <span
            v-if="!!kpi.numBuys"
            class="text--primary font-weight-medium rai-data"
            >{{ Math.round((100 * kpi.numTrades) / kpi.numBuys) }}%</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="4" class="py-2 px-0">
          <span class="text--secondary">Trade comp</span>
        </v-col>
        <v-col cols="4" class="py-2 px-0 text-right">
          <span
            v-if="comps.trades !== null"
            class="text--primary font-weight-medium rai-data"
            >${{ comps.trades }}</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
        <v-col cols="4" class="py-2 px-0 text-right">
          <span
            v-if="comps.numTrades !== null"
            class="text--primary font-weight-medium rai-data"
            >{{ comps.numTrades }} trades</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
        <v-col cols="4" class="py-2 px-0">
          <span class="text--secondary">% traded comp</span>
        </v-col>
        <v-col cols="4" class="py-2 px-0 text-right">
          <span
            v-if="!!comps.buys"
            class="text--primary font-weight-medium rai-data"
            >{{ Math.round((100 * comps.trades) / comps.buys) }}%</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
        <v-col cols="4" class="py-2 px-0 text-right">
          <span
            v-if="!!comps.numBuys"
            class="text--primary font-weight-medium rai-data"
            >{{ Math.round((100 * comps.numTrades) / comps.numBuys) }}%</span
          >
          <span v-else class="text--primary font-weight-medium rai-data"
            >--</span
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
