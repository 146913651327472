<script>
import Vue from "vue";

import { format } from "date-fns";
import { hasRouteDay } from "@/mixins/routerParams";
import { DRAWER_WIDTH, DRAWER_MINI_VARIANT_WIDTH } from "./constants";

const drawerBus = new Vue();

export default {
  name: "ShiftsDesktop",
  components: {
    ScheduleShiftList: () => import("@/components/daybook/ScheduleShiftList"),
    EmployeeAction: () => import("@/components/employees/EmployeeAction.js"),
  },
  mixins: [hasRouteDay],
  inject: {
    currentStore: {
      default: () => ({
        id: null,
        usesExternalScheduler: null,
      }),
    },
  },
  data() {
    return {
      drawerWidth: DRAWER_WIDTH,
      drawerMiniVariantWidth: DRAWER_MINI_VARIANT_WIDTH,
      expanded: false,
    };
  },
  // This is used to tell descendants when the drawer is opening/closing
  provide: {
    drawerBus,
  },
  computed: {
    formattedDate() {
      return format(this.dayDate, "MMMM do, yyyy");
    },
    miniVariant: {
      get() {
        return !this.expanded;
      },
      set(v) {
        return;
      },
    },
    showTimeSheetFeature() {
      return (
        this.$raiPos &&
        this.currentStore()?.usesExternalScheduler &&
        this.currentStore()?.usesTimeclock &&
        this.$store.getters["auth/isTeamMode"]
      );
    },
  },
  methods: {
    toggle() {
      const val = !this.expanded;
      drawerBus.$emit("drawer-expanded", val);
      this.expanded = val;
    },
    onDrawerTransition() {
      return this.toggle();
    },
    onTimeSheetClick() {
      this.$store.dispatch("teamMode/showTimeSheetDialog");
      this.$store.dispatch("teamMode/showEmployeeWelcomeDialog");
    },
  },
};
</script>

<template>
  <v-navigation-drawer
    class="schedule-drawer"
    data-testid="scheduleDrawer"
    :mini-variant-width="drawerMiniVariantWidth"
    :expand-on-hover="$vuetify.breakpoint.lgAndDown"
    :width="drawerWidth"
    absolute
    clipped
    right
  >
    <template #prepend>
      <EmployeeAction v-if="showTimeSheetFeature" v-slot="{ handleAction }">
        <v-list-item @click="handleAction(onTimeSheetClick)">
          <v-list-item-icon>
            <v-icon size="35">$calendarClock</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Time sheet </v-list-item-title>
            <v-list-item-subtitle> Overview of your work </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </EmployeeAction>

      <v-list-item>
        <v-list-item-icon>
          <v-icon size="35">$calendar</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Schedule</v-list-item-title>
          <v-list-item-subtitle>
            {{ formattedDate }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>
    <ScheduleShiftList />
  </v-navigation-drawer>
</template>
