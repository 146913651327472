<template>
  <v-footer
    v-bind="attrs"
    :class="['rai-expansion-footer', classes]"
    :min-height="minHeight"
    @blur.native="onBlur"
  >
    <v-card min-width="100%" color="transparent" flat>
      <slot name="activator" v-bind="context"></slot>
      <v-expand-transition>
        <div v-show="expanded" class="rai-expansion-footer__content">
          <slot></slot>
        </div>
      </v-expand-transition>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "RaiExpansionFooter",
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: [String, Number],
      default: 64,
    },
  },
  data: () => ({
    expanded: null,
  }),
  computed: {
    attrs() {
      return { ...this.$attrs, tabindex: this.expanded ? 0 : -1 };
    },
    classes() {
      return { "rai-expansion-footer--expanded": this.expanded };
    },
    context() {
      return {
        on: { click: this.onActivatorClick },
        attrs: {
          role: "button",
          "aria-haspopup": true,
          "aria-expanded": true,
        },
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.expanded = value;
      },
    },
    expanded: {
      handler(value) {
        value !== this.value && this.$emit("input", value);
      },
    },
  },
  methods: {
    onActivatorClick() {
      this.expanded = !this.expanded;
    },
    onBlur() {
      this.expanded = false;
    },
  },
};
</script>

<style lang="scss">
.rai-expansion-footer {
  z-index: 200;
}

.rai-expansion-footer__content {
  overflow-y: auto;
}
</style>
